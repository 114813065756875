import * as React from 'react'
import styled from '@emotion/styled'

import Page from '../templates/Page'
import Seo from '../components/seo'
import { colors, space } from '../utils/theme'
import { LeftTwoThirds, Container, Text, Hero, TwoColumnGrid } from '../utils/styles'

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const KontaktPage = () => {
  return (
    <Page useContainer={false}>
      <Seo title="Kontakt" />
      <Container style={{ marginBottom: space[20] }}>
      <Hero>SO KÖNNEN SIE UNS ERREICHEN</Hero>
        <TwoColumnGrid>
          <div>
          <Text>
          Telefon: 0911-604988
          <br/>
          Fax: 0911-607934
          <br/>
          Email: info@konstruktionsbuero-herga.de
          <br/>
          <br />
            Steilstraße 1a
            <br />
            90513 Zirndorf
          </Text>
        </div>
        <div>
          <iframe
            title="Herga Konstruktionsbüro Karte"
            src="https://snazzymaps.com/embed/365889"
            style={{
              width: '100%',
              height: '400px',
              border: 'none',
              zIndex: 2,
            }}
          ></iframe>
          </div>
        </TwoColumnGrid>
      </Container>
    </Page>
  )
}

export default KontaktPage
